import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { logo } from "../../Data/Images/Home";
import Language from "../../Layout/Header/Language";

const CustomFormulaSuccess = () => {
  const { state } = useLocation();
  const [formulaData, setFormulaData] = useState(() => {
    return state || JSON.parse(sessionStorage.getItem("formulaData"));
  });

  useEffect(() => {
    if (state) {
      sessionStorage.setItem("formulaData", JSON.stringify(state));
    }
  }, [state]);
  console.log("CustomFormulaSuccess => ", state);

  return (
    <>
      <div className="t-sticky t-top-0 t-z-10 t-w-full t-bg-secondary t-flex t-items-center t-justify-center t-py-2">
        <div className="container t-flex t-items-center t-justify-center">
          <img src={logo} alt="" width={60} />
          <div className="t-ml-auto">
            <Language />
          </div>
        </div>
      </div>
      <div className="t-w-full t-h-[80vh] product-suggestion-section section-padding t-flex t-flex-col t-items-center t-justify-center t-gap-4">
        <h2 className="text-center">Formula successfully created</h2>
        <h4 className="text-center">
          Click on the link below to access your created formula
        </h4>
        <a
          href={formulaData?.data?.customFormula?.formula_link}
          className="!t-text-base !t-px-8 t-py-2 !t-text-secondary"
        >
          {formulaData?.data?.customFormula?.formula_link}
        </a>
      </div>
    </>
  );
};

export default CustomFormulaSuccess;
