import React, { useState, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../../features/langSlice";
import { useGetContentsByTagWebQuery } from "../../features/contentsApiSlice";
import EffectAccordion from "./EffectAccordion";
import { selectSlug } from "../../features/contentsSlice";
import { Link } from "react-router-dom";
import { useGetAssetsQuery } from "../../features/quizApiSlice";
import FranceFlag from "../../assets/images/france.webp";
import GermanyFlag from "../../assets/images/germany.webp";
import NoMorePillsVideo from "../../assets/riise-people-sport-energy-granules.mp4";
import productImg from "../../assets/yellow-paper-pouch-granules.webp";
import Whatsapp from "../../assets/here1.webp";

import {
  MixedIn,
  ProducedIn,
  AsSeenIn,
  WhatAreYourWellnessGoals,
  ItMattersToUs,
  CustomerReviews,
  FormulaHeading,
  HereMainHeading,
  PersonaliseMine,
  FreeShippingBanner,
  BlackFriday,
  Off25,
  Energy,
  Focus,
  SportPerformance,
  Mood,
  Sleep,
  Stress,
  Digestion,
  ImmuneSystem,
  Skin,
  Hair,
  Nails,
  MusclePain,
  Eyes,
  BonesAndJoints,
  You,
  Heart,
  BannerHeading1,
  BannerHeading2,
  BannerText1,
  BannerText2,
  RecommendationButton,
  Your,
  FormulatedByExperts,
  Faq,
  FaqMore,
  FaqPage,
} from "../../Constants";
import Reviews from "../Customer/components/Reviews";
import { FOCUS_AREA_LINKS } from "../../utils";
import ReviewsOverlay from "../Reviews";
import PersonalFormulasSection from "./PersonalFormulasSection";
// import HereForYou from "./HereForYou";
import CountdownTimer from "../CountdownTimer";
import { Helmet } from "react-helmet";
import Made from "./Made";
import Unique from "./Unique";
import HighStandards from "./HighStandards";
import MillionCombinations from "./MillionCombinations";
import MakeChoice from "./MakeChoice";
import Partners from "./Partners";
import HereForYou from "../Customer/components/HereForYou";

const Home = () => {
  const selectedLang = useSelector(selectCurrentLanguage);
  const { t } = useTranslation();
  const [lang, setLang] = useState();
  const [mainSlides, setMainSlides] = useState(2);
  const [takeTheTestTitle, setTakeTheTestTitle] = useState("");
  const [pictures, setPictures] = useState([]);
  const slug = useSelector(selectSlug);
  const token = localStorage.getItem("customer_token");
  const quiz_id = localStorage.getItem("quiz_id");
  const [picture1, setPicture1] = useState("");
  const [picture4, setPicture4] = useState("");
  const [picture5, setPicture5] = useState("");

  const [picture7, setPicture7] = useState("");
  const [picture8, setPicture8] = useState("");

  const [picture9, setPicture9] = useState("");
  const [picture10, setPicture10] = useState("");
  const [picture11, setPicture11] = useState("");
  const [picture12, setPicture12] = useState("");

  const [picture13, setPicture13] = useState("");
  const [picture14, setPicture14] = useState("");
  const [picture15, setPicture15] = useState("");
  const [picture16, setPicture16] = useState("");

  const [picture17, setPicture17] = useState("");
  const [picture18, setPicture18] = useState("");
  const [picture19, setPicture19] = useState("");
  const [picture20, setPicture20] = useState("");
  const [picture21, setPicture21] = useState("");
  const [picture22, setPicture22] = useState("");

  const [expertsData, setExpertsData] = useState([]);

  const [data, setData] = useState([]);
  const [skip, setSkip] = useState(false);

  const {
    data: fetchContents,
    isSuccess,
    refetch,
  } = useGetContentsByTagWebQuery({ tag: "home", lang: lang, skip: skip });

  useEffect(() => {
    setLang(selectedLang === "us" ? "en" : selectedLang);

    refetch();
  }, [refetch, lang, selectedLang]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const handleResize = () => {
    if (window.innerWidth > 1024) {
      setMainSlides(3);
    } else if (window.innerWidth > 768) {
      setMainSlides(2);
    } else if (window.innerWidth > 0) {
      setMainSlides(1);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setData(fetchContents.data);
      if (fetchContents.data.length > 0) {
        setTakeTheTestTitle(fetchContents.data[24].title);
        let pics = [];

        setPicture1(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[0].picture}`
        );
        setPicture4(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[3].picture}`
        );
        setPicture5(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[4].picture}`
        );
        setPicture7(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[6].picture}`
        );
        setPicture8(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[7].picture}`
        );

        setPicture9(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[8].picture}`
        );

        setPicture10(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[9].picture}`
        );
        setPicture11(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[10].picture}`
        );
        setPicture12(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[11].picture}`
        );
        pics.push(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[4].picture}`
        );
        pics.push(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[6].picture}`
        );
        pics.push(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[7].picture}`
        );

        expertsData.push({ data: fetchContents.data[6], picture: pics[1] });
        expertsData.push({ data: fetchContents.data[7], picture: pics[2] });

        setPicture13(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[12].picture}`
        );
        setPicture14(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[13].picture}`
        );
        setPicture15(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[14].picture}`
        );
        setPicture16(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[15].picture}`
        );

        setPicture17(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[16].picture}`
        );
        setPicture18(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[17].picture}`
        );
        setPicture19(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[18].picture}`
        );
        setPicture20(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[19].picture}`
        );
        setPicture21(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[20].picture}`
        );
        setPicture22(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[21].picture}`
        );

        setPictures(pics);
      }
    }
  }, [fetchContents]);

  const [reviewData, setReviewData] = useState();
  useEffect(() => {
    const observer = new MutationObserver(() => {
      const reviewsCount = document.querySelectorAll(
        ".cssVar-subheading__number"
      );
      if (reviewsCount) {
        const reviews = [];
        reviewsCount.forEach((element) => {
          console.log("review => ", element.innerHTML);
          reviews.push(element.innerHTML);
        });
        setReviewData(reviews);
      }
    });

    // Start observing the target node for DOM changes
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    // Clean up the observer when the component unmounts
    return () => observer.disconnect();
  }, []);

  const sectionRef = useRef(null);
  const scrollToSectionEnd = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  };

  const { data: news } = useGetAssetsQuery("homepageNews");
  const { data: focusAreaIcons } = useGetAssetsQuery("focusAreaIcon");
  const { data: focusAreas } = useGetAssetsQuery("homepageFocusArea");

  const filteredFocusAreas = useMemo(
    () =>
      lang === "en"
        ? focusAreas?.data?.filter((_item, i) => i <= 14)
        : focusAreas?.data?.filter((_item, i) => i >= 15),
    [focusAreas, lang]
  );

  const [focusAreass, setFocusAreass] = useState([]);
  const [currentFocus, setCurrentFocus] = useState(null); // Initialize as null
  const [fadeClass, setFadeClass] = useState("fade-in");

  useEffect(() => {
    const updatedFocusAreass = [
      t(You),
      t(Energy),
      t(Focus),
      t(SportPerformance),
      t(Mood),
      t(Sleep),
      t(Stress),
      t(Digestion),
      t(ImmuneSystem),
      t(Skin),
      t(Hair),
      t(Nails),
      t(Heart),
      t(MusclePain),
      t(Eyes),
      t(BonesAndJoints),
    ];

    setFocusAreass(updatedFocusAreass);
    setCurrentFocus(updatedFocusAreass[0]);
  }, [t, selectedLang]);

  useEffect(() => {
    if (focusAreass.length === 0) return;

    let index = 0;
    const interval = setInterval(() => {
      setFadeClass("fade-out");
      setTimeout(() => {
        index = (index + 1) % focusAreass.length;
        setCurrentFocus(focusAreass[index]);
        setFadeClass("fade-in");
      }, 500);
    }, 2000);

    return () => clearInterval(interval);
  }, [focusAreass]);

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Discover personalized vitamins and supplements tailored to your unique needs with Riise. Take our 5-minute test to get started on your customized nutrition journey, backed by science."
        />
      </Helmet>
      <div className="t-flex t-bg-black t-py-2 t-items-center t-justify-center t-gap-1">
        <Link
          to={quiz_id ? "/product-suggestion" : "/quiz"}
          className="t-flex t-items-center t-font-bold t-text-white t-text-xs sm:t-text-base hover:t-text-white"
        >
          <p className="t-m-0">{t(BlackFriday)}</p>
          <p className="t-m-0 t-italic t-underline">{t(Off25)}&nbsp;&nbsp;</p>
          <p className="t-m-0 t-underline"> {t(FreeShippingBanner)}</p>
        </Link>
      </div>
      <main className="!t-w-[100vw] t-max-w-[100vw]">
        <section className="home-banner">
          <div className="container">
            <div className="home-text-wrapper t-h-[500px] md:!t-h-[520px]">
              <div className="row align-items-center !-t-mt-8">
                <div className="col-xxl-6 col-lg-8">
                  {/* <div className="home-text t-hidden sm:t-block">
                    {data[0] && (
                      <span
                        dangerouslySetInnerHTML={{ __html: data[0]?.contents }}
                      />
                    )}
                    <Made />
                    <Link to={slug}>
                      <span className="btn btn-custom-tertiary !t-rounded-lg">
                        {takeTheTestTitle}
                      </span>
                    </Link>
                  </div> */}
                  <div className="t-flex t-flex-col t-gap-4">
                    <div className="t-flex t-flex-col t-items-center sm:t-items-start -t-gap-2 t-mb-8">
                      <h1 className="t-text-center !t-m-0 t-text-secondary t-text-[24px] md:t-text-[30px]">
                        {t(BannerHeading1)}
                      </h1>
                      <h1 className="t-text-center !t-m-0 t-text-secondary t-text-[24px] md:t-text-[30px]">
                        {t(BannerHeading2)}
                      </h1>
                      <h1
                        className={`t-text-center !t-font-roboto t-text-[32px] md:t-text-[40px] t-font-[900] !t-m-0 t-text-secondary `}
                        style={{ fontFamily: "Roboto" }}
                      >
                        {" "}
                        <span className={fadeClass}>{currentFocus}</span>
                      </h1>
                    </div>
                    <div className="t-absolute t-bottom-32 sm:t-bottom-16 t-flex t-flex-col t-gap-4">
                      <p className="!t-m-0 t-text-secondary t-text-sm md:t-text-base t-w-[65%]">
                        {t(BannerText1)}
                      </p>
                      <p className="!t-m-0 t-text-secondary t-text-sm md:t-text-base t-w-[65%]">
                        {t(BannerText2)}
                      </p>
                      <div className="t-hidden sm:t-flex">
                        <Made />
                      </div>
                      <Link to={slug}>
                        <span className="btn btn-custom-tertiary !t-rounded-lg">
                          {takeTheTestTitle}
                        </span>
                      </Link>
                    </div>
                  </div>
                  {/* <div className={`t-absolute t-bottom-12 md:t-bottom-4`}>
                    <CountdownTimer isHomepage />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <div className="t-flex md:t-hidden t-items-center t-justify-center t-p-2">
          <CountdownTimer isHomepage />
        </div> */}
        <section className="t-flex t-items-center t-justify-center t-py-2 t-bg-gray-100 t-h-10">
          <ReviewsOverlay
            totalReviews={reviewData?.[1]?.trim() ?? 0}
            averageReviews={reviewData?.[0]?.trim() ?? 0}
            onClick={scrollToSectionEnd}
          />
        </section>
        <div className="t-flex sm:t-hidden t-items-center t-justify-center t-p-2 t-pb-0">
          <Made />
        </div>

        <section className="section-padding !t-px-8 !t-py-0 sm:!t-pt-4 t-h-[350px] md:t-h-[400px]">
          <h1 className="t-text-[24px] md:t-text-[30px] t-text-center t-font-bold t-mb-8 t-text-tertiary">
            {t(WhatAreYourWellnessGoals)}
          </h1>
          <div className="container !t-px-0 t-flex t-flex-row t-gap-2 md:t-gap-4 t-overflow-auto hide-scrollbar ">
            {filteredFocusAreas?.map((item, index) => {
              const focusIcon = focusAreaIcons?.data?.find(
                (i) => i.name === item.name
              );
              return (
                <Link
                  to={FOCUS_AREA_LINKS[item.name]}
                  className={`t-relative t-flex-none t-w-[200px] t-h-[200px] t-flex !i-items-start !t-py-4 t-gap-2 t-rounded-[0.2rem] t-px-2 t-ml-0 t-mr-2 `}
                  style={{
                    backgroundImage: `linear-gradient(180deg, rgba(29, 29, 31, 0.10) 0%, rgba(29, 29, 31, 0.75) 100%), url(${
                      process.env.REACT_APP_PUBLIC_URL
                    }${item?.url.replace("focusareas", "focusarea")})`,
                    backgroundSize: "cover",
                  }}
                  key={"focus-areas-home-" + index}
                >
                  <img
                    src={`${process.env.REACT_APP_PUBLIC_URL}${focusIcon?.url}`}
                    alt=""
                    className="t-w-5 t-h-5 t-mb-[-2px] t-mt-auto"
                  />
                  <p
                    className="t-text-white t-font-[600] t-text-[15px] t-leading-4 t-m-0 t-mt-auto"
                    style={{ fontFamily: "Roboto" }}
                  >
                    {item?.name}
                  </p>
                </Link>
              );
            })}
          </div>
        </section>

        <div className="t-max-w-[100vw] t-flex t-items-center t-justify-center">
          <Unique />
        </div>
        <section className="experts section-padding-exports !t-bg-white !t-pb-4 !t-pt-10 t-h-[420px]  md:t-h-[555px]">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="title-wrapper mb-4">
                  <h2 className="t-text-[24px] md:t-text-[30px] !t-text-center t-font-bold t-text-tertiary">
                    {t(FormulatedByExperts)}
                  </h2>
                </div>
              </div>
            </div>

            <div className="experts-wrapper t-flex t-items-start t-gap-4">
              <div className="col-6">
                <div className=" img-style !t-flex !t-flex-col !t-items-center">
                  <img
                    src={picture7}
                    alt=""
                    className="!t-h-auto !t-w-[80%] md:!t-w-[40%]"
                  />
                  <h3>{expertsData[0] && expertsData[0]?.data?.title}</h3>
                  {expertsData[0] && (
                    <p
                      className="t-leading-[100%]"
                      dangerouslySetInnerHTML={{
                        __html: expertsData[0]?.data?.contents,
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="img-style !t-flex !t-flex-col !t-items-center">
                  <img
                    src={picture8}
                    alt=""
                    className="!t-h-auto !t-w-[80%] md:!t-w-[40%]"
                  />
                  <h3>{expertsData[1] && expertsData[1]?.data?.title}</h3>
                  {expertsData[1] && (
                    <p
                      className="t-leading-[100%]"
                      dangerouslySetInnerHTML={{
                        __html: expertsData[1]?.data?.contents,
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="t-flex t-items-center t-justify-center">
          <HighStandards />
        </div>

        <section className="section-padding !t-pt-4 !t-pb-0 t-h-[102px]">
          {/* <h1 className="t-text-[18px] t-text-center t-font-bold t-mb-0 t-text-tertiary">
            {t(AsSeenIn)}
          </h1> */}
          <div className="container t-flex t-flex-row t-items-center t-justify-center t-gap-2 md:t-gap-4 lg:t-gap-8">
            {news?.data?.map((item) => (
              <img
                src={`${process.env.REACT_APP_PUBLIC_URL}${item?.url}`}
                alt=""
                className="t-w-16 t-h-auto md:t-w-[5%]"
                key={"news-img-" + item.url}
              />
            ))}
          </div>
        </section>
        <div className="t-flex t-items-center t-justify-center">
          <MillionCombinations slug={slug} />
        </div>

        <section className="services section-padding !pb-0 t-bg-white t-h-[360px] md:t-h-[460px]">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="title-wrapper">
                  <h3 className="t-text-[24px] md:t-text-[30px] t-text-center t-font-bold t-text-tertiary">
                    {data[1] && (
                      <span
                        dangerouslySetInnerHTML={{ __html: data[1]?.title }}
                      />
                    )}
                  </h3>
                  {/* <h6 className="sub-title-uppercase t-text-center">
                    {data[1] && (
                      <span
                        dangerouslySetInnerHTML={{ __html: data[1]?.contents }}
                      />
                    )}
                  </h6> */}
                </div>
              </div>
            </div>
            <div className="sm:t-hidden t-flex t-flex-row t-items-center t-gap-4 -t-mt-8">
              <img src={productImg} alt="" className="!t-h-[80%] !t-w-[30%]" />
              <div className="t-flex t-flex-col t-justify-between t-gap-8 t-mt-4 ">
                {data[12] && (
                  <h5
                    className="t-text-[18px] t-text-tertiary t-font-bold"
                    dangerouslySetInnerHTML={{ __html: data[12]?.title }}
                  />
                )}
                {data[13] && (
                  <h5
                    className="t-text-[18px] t-text-tertiary t-font-bold"
                    dangerouslySetInnerHTML={{ __html: data[13]?.title }}
                  />
                )}
                {data[14] && (
                  <h5
                    className="t-text-[18px] t-text-tertiary t-font-bold"
                    dangerouslySetInnerHTML={{ __html: data[14]?.title }}
                  />
                )}
              </div>
            </div>
            <div className="!t-hidden sm:!t-flex row gy-4 t-items-start t-justify-center">
              <div className="col-xl-3 col-md-6">
                <div className="services-item">
                  <img
                    src={picture15}
                    alt=""
                    className="img-fluid d-none d-sm-block"
                  />
                  <div className="services-meta">
                    {data[12] && (
                      <h4
                        className="col-xl-8 px-0"
                        dangerouslySetInnerHTML={{ __html: data[12]?.title }}
                      />
                    )}
                    {data[12] && (
                      <p
                        dangerouslySetInnerHTML={{ __html: data[12]?.contents }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6">
                <div className="services-item">
                  <img
                    src={picture14}
                    alt=""
                    className="img-fluid d-none d-sm-block"
                  />
                  <div className="services-meta">
                    {data[13] && (
                      <h4
                        className="col-xl-8 px-0"
                        dangerouslySetInnerHTML={{ __html: data[13]?.title }}
                      />
                    )}
                    {data[13] && (
                      <p
                        dangerouslySetInnerHTML={{ __html: data[13]?.contents }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6">
                <div className="services-item">
                  <img
                    src={picture13}
                    alt=""
                    className="img-fluid d-none d-sm-block"
                  />
                  <div className="services-meta">
                    {data[14] && (
                      <h4
                        className="col-xl-8 px-0"
                        dangerouslySetInnerHTML={{ __html: data[14]?.title }}
                      />
                    )}
                    {data[14] && (
                      <p
                        dangerouslySetInnerHTML={{ __html: data[14]?.contents }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          ref={sectionRef}
          className="steps section-padding !t-pb-0 !t-bg-gray-100 t-h-[501px]  md:t-h-[480px]"
        >
          <div className="container">
            <h2 className="t-text-[24px] md:t-text-[30px] !t-text-center t-font-bold t-text-tertiary">
              {t(CustomerReviews)}
            </h2>
            <Reviews />
          </div>
        </section>

        <section className="steps section-padding !t-pb-8 t-h-[620px]  md:t-h-[605px] !t-bg-white">
          <div className="t-mb-8">
            <h2 className="t-text-[24px] md:t-text-[30px] !t-text-center t-font-bold t-text-tertiary">
              {t(FormulaHeading)}
            </h2>
          </div>

          <PersonalFormulasSection />
          <div className="!t-mt-8 t-flex t-items-center t-justify-center">
            <div className="col-auto !t-mx-auto">
              <Link to={slug}>
                <span className="btn btn-custom-secondary !t-rounded-lg">
                  {t(PersonaliseMine)}
                </span>
              </Link>
            </div>
          </div>
        </section>

        <section className="steps section-padding video-bg d-flex align-items-center justify-content-center t-h-[400px] sm:t-h-[400px]">
          <div
            dangerouslySetInnerHTML={{
              __html: `<video className="sm:!t-hidden" autoplay loop muted playsinline>
                      <source
                          src=${NoMorePillsVideo}
                          data-src="assets/riise-people-sport-energy-granules.mp4"
                          type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>`,
            }}
          />
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-12 text-center !t-max-w-[500px] t-mx-auto">
                {/*
                <h3 className="t-text-[24px] md:t-text-[30px] !t-text-center t-font-bold t-text-white">
                  {data[3] && (
                    <span
                      dangerouslySetInnerHTML={{ __html: data[3]?.title }}
                    />
                  )}
                </h3>
                <h6>
                  {data[3] && (
                    <span
                      dangerouslySetInnerHTML={{ __html: data[3]?.contents }}
                    />
                  )}
                </h6>
                <div className="text-center mt-5">
                  <div className="col-auto">
                    <Link to={slug}>
                      <span className="btn btn-custom-tertiary !t-rounded-lg">
                        {takeTheTestTitle}
                      </span>
                    </Link>
                  </div>
                </div>
                */}
              </div>
            </div>
          </div>
        </section>

        <div className="t-flex t-items-center t-justify-center t-mt-2">
          <MakeChoice />
        </div>
        <div className="t-flex t-items-center t-justify-center t-bg-gray-100">
          <Partners />
        </div>

        <section className="faq">
          <div className="container-fluid text-center px-0 ">
            <div className="row gx-0 !t-max-w-[1330px] !t-mx-auto">
              <h3 className="t-text-[24px] md:t-text-[30px] !t-text-center t-font-bold t-text-tertiary t-pt-4">
                {t(Faq)}
              </h3>
              <div className="col-lg-12 align-items-center d-flex faq-home">
                <EffectAccordion data={data} />
              </div>
              <p className="!t-text-center t-mx-auto !t-text-secondary t-text-base">
                {t(FaqMore)}{" "}
                <span>
                  <Link to="/faqs" className="t-underline !t-text-secondary">
                    {t(FaqPage)}
                  </Link>
                </span>
              </p>
            </div>
          </div>
        </section>
        <section className="steps section-padding t-max-w-[700px] t-mx-auto !t-pb-8 !t-bg-white t-h-[400px] md:t-h-[412px] !t-px-4">
          <div className="t-mb-8">
            <h3 className="t-text-[24px] md:t-text-[30px] !t-text-center t-font-bold t-text-tertiary">
              {t(HereMainHeading)}
            </h3>
          </div>

          <HereForYou />
        </section>
      </main>
      <a
        href="https://wa.me/+4915156047816"
        target="_blank"
        rel="noreferrer"
        className="t-fixed t-bottom-24 sm:t-bottom-5 t-right-7 t-z-50"
      >
        <img
          src={Whatsapp}
          alt=""
          className="t-h-10 t-w-10 md:t-h-15 md:t-w-15"
        />
      </a>
    </>
  );
};

export default Home;
